<template>
	<v-card width="100%" class="d-flex flex-column">
		<v-card-title class="list_title">
			<v-btn icon small class="mr-2" @click="$router.back()">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			{{ training.name}}
		</v-card-title>
		<v-card-actions class="pt-0 pb-0">
			<buttons :training="training" />
		</v-card-actions>
		<v-divider></v-divider>
		<v-card-actions>
			<v-layout align-center style="width: 50%">
				<v-autocomplete :items="trainees"
				                outlined
				                item-text="text"
				                item-value="id"
				                v-model="selected"
				                label="교육생 선택(전체~개별)"
				                hide-details
				                dense
				                class="mr-2 flex-grow-1"
				                style="width: 100px;"
				>
				</v-autocomplete>
				<v-text-field v-model="reason"
				              outlined
				              dense
				              hide-details
				              class="mr-2"
				              label="지급 사유" />
				<v-text-field v-model="point" type="number"
				              outlined
				              dense
				              hide-details
				              class="mr-2"
				              style="width: 50px;"
				              label="지급 포인트" />
				<v-btn color="kb-primary-color" @click="send"
				       :disabled="!selected || !point"
				>{{ point < 0 ? '차감' : '지급'}}</v-btn>
			</v-layout>
			<v-spacer></v-spacer>
			<v-spacer></v-spacer>
		</v-card-actions>
		<v-card-text>

			<v-data-table
					item-key="id"
					:headers="headers"
					:items="pointList"
					:items-per-page="itemsPerPage"
					:page.sync="page"
					:loading="isLoading"
					:search="search"
					loading-text="데이터 조회 중.. 잠시만 기다리세요."
					hide-default-footer
					@page-count="pageCount = $event"
			>
				<template v-slot:item.trainee.division="{ item }">
					{{ item.trainee.division || '-' }}
				</template>
				<template v-slot:item.reason="{ item }">
					{{ item.reason || '-' }}
				</template>
				<template v-slot:item.created_at="{ item }">
					{{ item.created_at | moment('YYYY-MM-DD HH:mm:ss')}}
				</template>
				<template v-slot:item.action="{ item }">
					<v-btn @click="deletePoint(item)">삭제</v-btn>
				</template>
			</v-data-table>
			<v-divider></v-divider>
			<div class="text-center pt-2 mt-5">
				<v-pagination
						v-model="page"
						:length="pageCount"
				></v-pagination>
			</div>
		</v-card-text>

	</v-card>
</template>

<script>
	import Buttons from "./Buttons";
	export default {
		components: {
			Buttons
		},
		data() {
			return {
				training: {},
				trainees: [],
				selected: null,
				reason: '',
				point: '',
				pointList: [],
				search: '',

				page: 1,
				pageCount: 0,
				itemsPerPage: 10,
				headers: [
					{text: '이름', sortable: true, value: 'trainee.name'},
					{text: '소속', sortable: true, value: 'trainee.division' },
					{text: '지급 사유', sortable: true, value: 'reason' },
					{text: '지급 포인트', sortable: true, value: 'point' },
					{text: '지급 일시', sortable: true, value: 'created_at' },
					{text: '삭제', sortable: true, value: 'action' },
				],
				isLoading: true,
			}
		},

		mounted() {
			this.training.id = this.$route.params.id

			this.getTraineeList()
			this.getList()
		},

		methods: {

			getTraineeList() {

				this.isLoading = true
				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training/${this.training.id}`).then(response => {
					if (response.data.code === '0000') {
						this.training = response.data.result
						this.trainees = [{id: -1, text: '교육생 전체'}]
						this.names = {}
						this.names[-1] = '교육생 전체'
						this.training.trainees.forEach(trainee => {
							trainee.text = `${trainee.name} (${trainee.email})`
							this.trainees.push(trainee)
							this.names[trainee.id] = trainee.text
						})
						this.isLoading = false
					}
				})
			},

			getList() {

				const params = {
					training_id: this.training.id
				}

				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/points`, {params:params}).then(response => {
					if (response.data.code === '0000') {
						this.pointList = []
						response.data.result.forEach(item => {
							if (item.trainee_id === -1) {
								item.trainee = {
									name: '교육생 전체',
									division: '',
								}
							}

							this.pointList.push(item)
						})
					}
				})
			},

			send() {
				this.$eventBus.$emit('SEND_POINT', this.training.id, this.selected, this.point)

				const params = {
					training_id: this.training.id,
					trainee_id: this.selected,
					reason: this.reason,
					point: this.point,
				}

				this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/points`, params).then(response => {
					if (response.data.code === '0000') {
						this.point = ''

						this.getList()
					}
				})

			},

			deletePoint(item) {
				this.$eventBus.$emit('SEND_POINT', this.training.id, item.trainee_id, -item.point)

				this.axios.delete(`${process.env.VUE_APP_SERVER_HOST}/api/admin/points/${item.id}`).then(response => {
					if (response.data.code === '0000') {

						this.getList()
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
