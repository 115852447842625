<template>
	<v-card width="100%" class="d-flex flex-column">
		<v-card-actions class="pb-0">
			<buttons />
		</v-card-actions>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="장소, 단서종류, 단서대상, 단서내용"
					single-line
					hide-details
			></v-text-field>
		</v-card-actions>
		<v-card-text>

			<v-data-table
					item-key="id"
					:headers="headers"
					:items="hints"
					:items-per-page="itemsPerPage"
					:page.sync="page"
					:loading="isLoading"
					:search="search"
					loading-text="데이터 조회 중.. 잠시만 기다리세요."
					hide-default-footer
					@page-count="pageCount = $event"
			>
				<template v-slot:item.content="{ item }">
					<div style="width: 300px;" v-if="item.content" v-html="
					item.content.replace(/\n/g, '<br />')
						.replace(/\[username([0-9]+)\]/g, '{$1}')
						.replace(/\[([^\]]+)\]/g, '<span class=\'highlight\'>$1</span>')
						.replace(/\{([^\}]+)\}/g, '[username$1]')
					"></div>
				</template>
				<template v-slot:item.character_id="{ item }">
					<span v-if="item.character_id">[username{{item.character_id}}]</span>
				</template>
				<template v-slot:item.action="{ item }">
					<v-btn @click="editItem(item)">편집</v-btn>
				</template>
			</v-data-table>
			<v-divider></v-divider>
			<div class="text-center pt-2 mt-5">
				<v-pagination
						v-model="page"
						:length="pageCount"
				></v-pagination>
			</div>
		</v-card-text>


		<v-dialog v-if="item" v-model="showDialog" width="900" style="overflow-x:hidden">
			<v-card>
				<v-card-title>
					단서 수정
				</v-card-title>
				<v-card-text>
					<v-layout justify-center column>
						<v-row no-gutters>
							<v-col cols="3">
								<v-subheader>장소</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center">
								{{ item.hint.map.name }}
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="3">
								<v-subheader>단서 종류</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center">
								{{ item.hint.name}}
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="3">
								<v-subheader>단서 대상</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center">
								<span v-if="item.character_id">[username{{ item.character_id}}]</span>
								<span v-else>-</span>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="3">
								<v-subheader>단서 내용</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center" class="pt-5">
								<v-textarea v-model="content"
								            full-width
								            rows="10"
								            outlined
								></v-textarea>
								<span class="hint">* [username1] 등의 단서 대상은 교육생이 입력한 이름으로 치환되고, 그 외에 대괄호([ 또는 ])로 감싼 영역은 하이트라이트 처리됩니다.</span>
							</v-col>
						</v-row>
					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="kb-primary-color" @click.native="save">수정</v-btn>
					<v-btn @click.native="showDialog=false">취소</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-card>
</template>

<script>
	import Buttons from "./Buttons";
	export default {
		components: {
			Buttons
		},
		data() {
			return {
				training: {},
				trainees: [],
				selected: null,
				hints: [],
				item: null,
				content: '',

				showDialog: false,

				search: '',
				page: 1,
				pageCount: 0,
				itemsPerPage: 10,
				headers: [
					{text: '#', sortable: true, value: 'id'},
					{text: '장소', sortable: true, value: 'hint.map.name' },
					{text: '단서 종류', sortable: true, value: 'hint.name' },
					{text: '단서 대상', sortable: true, value: 'character_id' },
					{text: '단서 내용', sortable: true, value: 'content' },
					{text: '', sortable: true, value: 'action' },
				],
				isLoading: true,
			}
		},

		mounted() {
			this.getList()
		},

		methods: {

			getList() {

				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/hints`).then(response => {
					if (response.data.code === '0000') {
						this.hints = response.data.result
						this.isLoading = false
					}
				})
			},

			editItem(item) {
				this.item = item
				this.content = item.content
				this.showDialog = true
			},

			save() {

				this.item.content = this.content
				this.showDialog = false

				const params = {
					id: this.item.id,
					content: this.content,
				}

				this.isLoading = true
				this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/hints`, params).then(response => {
					if (response.data.code === '0000') {
						this.isLoading = false
						this.getList()

						this.$store.commit('showSnackbar', {
							message: '수정했습니다.',
							color: 'success',
							duration: 3000,
						})
					}
				})
			}
		}
	}
</script>

<style>
	.highlight {
		font-weight: 600;
		color: #FEBD36;
	}
	.hint {
		color: #bd2130;
	}
</style>
