import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookie from 'vue-cookie'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import vuetify from './plugins/vuetify'
import VueMoment from 'vue-moment'

import eventBus from './plugins/EventBus'
import store from './plugins/Store'

import './assets/styles/main.scss'

import QrcodeVue from 'qrcode.vue'

global.jQuery = require('jquery');
const $ = global.jQuery;
window.$ = $;

window.moment = require('moment')

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueCookie)
Vue.use(VueMoment)
Vue.use(eventBus)

new Vue({
	vuetify,
	eventBus,
	router,
	store,
	QrcodeVue,
	render: h => h(App)
}).$mount('#app')
