<template>
	<v-card width="100%" class="d-flex flex-column">
		<v-card-actions class="pb-0">
			<buttons />
		</v-card-actions>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="kb-primary-color" @click="addItem">Quiz 추가</v-btn>
		</v-card-actions>
		<v-card-text>
			<v-data-table
					item-key="id"
					:headers="headers"
					:items="quizList"
					:items-per-page="itemsPerPage"
					:page.sync="page"
					:loading="isLoading"
					loading-text="데이터 조회 중.. 잠시만 기다리세요."
					hide-default-footer
					@page-count="pageCount = $event"
			>
				<template v-slot:item.question="{ item }">
					<p>{{item.question}}</p>
					<span v-for="n in 4" v-if="item[`option${n}`]" v-html="`&#${9311 + n} ${item[`option${n}`]}`"
					      style="display:block;"
					></span>
				</template>
				<template v-slot:item.action="{ item }">
					<v-btn small color="secondary" @click="editItem(item)">편집</v-btn>
					<v-btn small color="error" class="ml-2" @click="deleteItem(item)">삭제</v-btn>
				</template>
			</v-data-table>
			<v-divider></v-divider>
			<div class="text-center pt-2 mt-5">
				<v-pagination
						v-model="page"
						:length="pageCount"
				></v-pagination>
			</div>
		</v-card-text>


		<v-dialog v-model="showDialog" width="900" style="overflow-x:hidden">
			<v-card>
				<v-card-title>
					Quiz {{ item.id ? '수정' : '추가'}}
				</v-card-title>
				<v-card-text>
					<v-layout justify-center column>
						<v-row no-gutters>
							<v-col cols="3">
								<v-subheader>할당 Quiz 미션</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center">
								<v-select v-model="mission" :items="missions" dense outlined></v-select>
							</v-col>
							<v-col cols="3">
								<v-subheader>문제</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center">
								<v-textarea
										v-model="question"
										rows="2"
										full-width
										solo
								></v-textarea>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="3">
								<v-subheader>보기</v-subheader>
								<v-btn :disabled="Object.keys(options).length >= 4" @click="addOption">보기 추가</v-btn>
							</v-col>
							<v-col cols="9" align-self="center">
								<v-layout v-for="n in 4" v-if="typeof options[n] !== 'undefined'" align-center>
									<span v-html="`&#${9311 + n}`" />
									<v-checkbox v-model="answer" class="ml-2" :value="n"
									            dense
									            @click="answer = n" />
									<v-text-field v-model="options[n]" hide-details dense
									              class="ml-2"
									/>
									<v-btn v-if="Object.keys(options).length > 1" icon small @click="deleteOption(n)"><v-icon>mdi-close</v-icon></v-btn>
								</v-layout>
							</v-col>
						</v-row>
					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="kb-primary-color" @click.native="save">{{item.id ? '수정' : '추가'}}</v-btn>
					<v-btn @click.native="showDialog=false">취소</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-card>
</template>

<script>
	import Buttons from "./Buttons";
	export default {
		components: {
			Buttons
		},
		data() {
			return {
				training: {},
				trainees: [],
				selected: null,
				quizList: [],
				item: {},
				missions: [
					{text: 'Quiz 1', value: 1},
					{text: 'Quiz 2', value: 2},
					{text: 'Quiz 3', value: 3},
					{text: 'Quiz 4', value: 4},
					{text: 'Quiz 5', value: 5},
				],
				mission: null,
				question: '',
				options: {1: ''},
				answer: null,

				showDialog: false,

				page: 1,
				pageCount: 0,
				itemsPerPage: 10,
				headers: [
					{text: '#', sortable: true, value: 'id'},
					{text: '할당 Quiz 미션', sortable: true, value: 'name' },
					{text: 'Quiz 내용', sortable: true, value: 'question' },
					{text: '정답', sortable: true, value: 'correct_answer' },
					{text: '', sortable: true, value: 'action' },
				],
				isLoading: true,
			}
		},

		watch: {
			showDialog(val) {
				if (!val) {
					this.item = {}
				}
			}
		},

		mounted() {
			this.getList()
		},

		methods: {

			getList() {

				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/quiz`).then(response => {
					if (response.data.code === '0000') {
						this.quizList = response.data.result
						this.isLoading = false
					}
				})
			},

			addItem() {
				this.item = {}
				this.mission = '',
				this.question = '';
				this.options = {1: ''}
				this.answer = ''
				this.showDialog = true
			},

			editItem(item) {
				this.item = item
				this.mission = item.sequence
				this.question = item.question
				for (let n of [1,2,3,4]) {
					if (item[`option${n}`]) {
						this.options[n] = item[`option${n}`]
					}
				}
				this.answer = item.correct_answer
				this.showDialog = true
			},

			addOption() {
				const seq = Object.keys(this.options).length + 1
				this.$set(this.options, seq, '')
			},

			deleteOption(n) {

				for (let i=n+1; i<=4; i++) {
					if (!this.options[i]) continue
					this.$set(this.options, i-1, this.options[i])
				}
				this.$delete(this.options, Object.keys(this.options).length)
			},

			save() {

				this.showDialog = false

				const params = {
					id: this.item.id,
					sequence: this.mission,
					name: `Quiz ${this.mission}`,
					question: this.question,
					option1: this.options[1] ? this.options[1] : null,
					option2: this.options[2] ? this.options[2] : null,
					option3: this.options[3] ? this.options[3] : null,
					option4: this.options[4] ? this.options[4] : null,
					correct_answer: this.answer,
				}

				this.isLoading = true
				this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/quiz`, params).then(response => {
					if (response.data.code === '0000') {
						this.isLoading = false
						this.getList()

						this.$store.commit('showSnackbar', {
							message: '수정했습니다.',
							color: 'success',
							duration: 3000,
						})
					}
				})
			},
			deleteItem(item) {
				this.$store.commit('showDialog', {
					type: 'confirm',
					message: 'Quiz를 삭제하시겠습니까?',
					okCb: _ => {
						this.isLoading = true
						this.axios.delete(`${process.env.VUE_APP_SERVER_HOST}/api/admin/quiz/${item.id}`).then(response => {
							if (response.data.code === '0000') {
								this.isLoading = false
								this.getList()

								this.$store.commit('showSnackbar', {
									message: '삭제했습니다.',
									color: 'success',
									duration: 3000,
								})
							}
						})
					}
				})
			}
		}
	}
</script>

<style>
	.highlight {
		font-weight: 600;
		color: #FEBD36;
	}
	.hint {
		color: #bd2130;
	}
</style>
