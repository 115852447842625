<template>
	<v-card width="100%" class="d-flex flex-column">
		<v-card-title class="list_title">
			<v-btn icon small class="mr-2" @click="$router.back()">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			{{ training.name}}
		</v-card-title>
		<v-card-actions class="pt-0 pb-0">
			<buttons :training="training" />
		</v-card-actions>
		<v-divider></v-divider>
		<v-card-text class="fill-height">
			<v-row class="fill-height">
				<v-col cols="3">
					<v-autocomplete :items="trainees"
									outlined
									item-text="text"
									item-value="id"
									v-model="selected"
									label="발송대상 검색/선택"
									hide-details
									dense
									class="mr-10 flex-grow-1"
					                @change="onChangeTrainee"
									>
					</v-autocomplete>
					<v-list dense>
						<v-subheader>메시지 목록</v-subheader>
						<v-list-item-group
								color="primary"
								v-model="selectedList"
								@change="onChange"
						>
							<v-list-item
									v-for="(item, i) in lists"
									:key="i"
									class="list-item"
									:class="item.isNew ? 'new' : ''"
							>
								<v-list-item-content>
									<v-list-item-title v-text="names[item.trainee_id]"></v-list-item-title>
									<v-list-item-content v-text="item.message" class="list-message"></v-list-item-content>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-col>
				<v-col cols="9">
					<v-layout column fill-height>
						<div class="message-body d-flex flex-column-reverse mb-2 flex-nowrap">
							<div class="message-item"
							     v-for="(message, index) in messages"
							     :key="index"
							     :class="message.from_admin ? 'me' : ''"
							     v-if="message.trainee_id == selected"
							>
								<div v-if="!message.from_admin" class="trainee">{{names[message.trainee_id]}}</div>
								<div class="message">
									{{ message.message }}
								</div>
							</div>
						</div>
						<div class="message-wrap">
							<v-textarea auto-grow solo outlined
										v-model="message"
										hide-details
										:disabled="!this.selected"
										full-width
										:label="this.selected ? '메시지' : '발송대상을 선택하세요.'"
							>
							</v-textarea>
							<v-btn class="send" color="kb-primary-color"
								   @click="send()"
							       :disabled="!this.selected"
							>
								발송
							</v-btn>
						</div>
					</v-layout>
				</v-col>
			</v-row>
		</v-card-text>

	</v-card>
</template>

<script>
	import Buttons from "./Buttons";
	export default {
		components: {
			Buttons
		},
		data() {
			return {
				training: {},
				trainees: [],
				names: {},
				selected: null,
				message: '',
				messages: [],
				lists: [],
				selectedList: null,
			}
		},

		mounted() {
			this.training.id = this.$route.params.id

			this.getTraineeList()
			this.getList()

			this.$eventBus.$on('RECEIVED_MESSAGE', trainee => {
				this.getList(trainee)
			})
		},

		methods: {

			getTraineeList() {

				this.isLoading = true
				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training/${this.training.id}`).then(response => {
					if (response.data.code === '0000') {
						this.training = response.data.result
						this.trainees = [{id: -1, text: '교육생 전체'}]
						this.names = {}
						this.names[-1] = '교육생 전체'
						this.training.trainees.forEach(trainee => {
							trainee.text = `${trainee.name} (${trainee.email})`
							this.trainees.push(trainee)
							this.names[trainee.id] = trainee.text
						})
						this.isLoading = false
					}
				})
			},

			getList(trainee) {
				const messages = JSON.parse(localStorage.getItem('messages') || '[]')

				this.messages = (messages[this.training.id] || []).reverse()

				const lists = []
				this.messages.forEach(message => {
					let exists = false
					lists.forEach(list => {
						if (message.trainee_id === list.trainee_id) {
							exists = true
						}
					})

					if (!exists) {
						if (trainee === message.trainee_id && trainee !== this.selected) {
							message.isNew = true
						}
						lists.splice(0, 0, message)
					}
				})

				lists.reverse()
				this.lists = lists

				$('.message-body').scrollTop($('.message-body').get(0).scrollHeight)
			},

			send() {
				if (!this.selected) return

				this.$eventBus.$emit('SEND_MESSAGE', this.training.id, this.selected, this.message)

				this.message = ''

				this.getList()
			},
			onChangeTrainee(id) {
				let index = -1

				this.lists.forEach((list, idx) => {
					if (list.trainee_id == id) {
						index = idx
					}
				})

				this.selectedList = index
			},
			onChange(index) {
				if (typeof index === 'undefined') return
				const self = this
				const list = this.lists[index]

				console.log(this.lists)
				console.log(index)

				if (list) list.isNew = false

				this.trainees.forEach((trainee, idx) => {
					if (trainee.id === list.trainee_id) {
						self.selected = trainee.id
					}
				})

			}
		}
	}
</script>

<style lang="scss" scoped>
	.message-body{
		color: #787067;
		font-size: 15px;
		border: 1px solid #efefef;
		background-color: #febd36;
		padding: 20px;
		overflow-y: scroll;
		height: 350px;

		.trainee {
			font-weight: bold;
		}
		.message {
			display: inline-block;
			border-radius: 0px 5px 5px 5px;
			background-color: #ffffff;
			padding: 1%;
			max-width: 45%;
			word-break: keep-all;
		}

		.message-item {
			padding-top: 5px;
		}

		.me {
			text-align: right;

			.message {
				background-color: #787067;
				color: #ffffff;
				border-radius: 5px 0 5px 5px;
			}

		}
	}

	.message-wrap {
		position: relative;
		.send {
			position: absolute;
			right: 5px;
			bottom: 5px;
		}
	}

	.list-item {
		border: 1px solid #efefef;
		margin: 2px 0;

		.list-message {
			font-size: 12px; color: #999;
		}
	}

	.new {
		background-color: #ffe5e1;

		&:before {
			opacity: 0 !important;
		}
	}
</style>
