<template>
	<v-card width="100%" class="d-flex flex-column">
		<v-card-title class="list_title">
			<v-btn icon small class="mr-2" @click="$router.back()">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			{{ training.name}}
		</v-card-title>
		<v-card-actions class="pt-0 pb-0">
			<buttons :training="training" />
		</v-card-actions>
		<v-card-text>
			<v-card>
				<v-card-title>대표 강점 최종 선택</v-card-title>
				<v-card-actions>
					<v-btn color="kb-primary-color"
					       @click="start()"
					>시작하기</v-btn>
					<v-btn
					       @click="reset()"
					>게임 진행 중으로 리셋</v-btn>
				</v-card-actions>
			</v-card>
		</v-card-text>
		<v-card-actions class="mt-5">
			<h5 class="ml-6">교육생 선택 대표 강점</h5>
			<v-spacer></v-spacer>
			<v-btn @click="downloadExcel">Excel 다운로드</v-btn>
		</v-card-actions>
		<v-card-text>
			<v-data-table
					v-model="selected"
					item-key="id"
					show-select
					:headers="headers"
					:items="traineeStrengths"
					:items-per-page="itemsPerPage"
					:page.sync="page"
					:loading="isLoading"
					:search="search"
					loading-text="데이터 조회 중.. 잠시만 기다리세요."
					hide-default-footer
					@page-count="pageCount = $event"
			>
				<template v-slot:item.first="{ item }">
					<img v-if="item.first_1" :src="`/images/icon_strength${item.first_1}.png`" width="80" class="ml-1" />
					<img v-if="item.first_2" :src="`/images/icon_strength${item.first_2}.png`" width="80" class="ml-1" />
					<img v-if="item.first_3" :src="`/images/icon_strength${item.first_3}.png`" width="80" class="ml-1" />
					<img v-if="item.first_4" :src="`/images/icon_strength${item.first_4}.png`" width="80" class="ml-1" />
					<img v-if="item.first_5" :src="`/images/icon_strength${item.first_5}.png`" width="80" class="ml-1" />
				</template>
				<template v-slot:item.final="{ item }">
					<img v-if="item.final_1" :src="`/images/icon_strength${item.final_1}.png`" width="80" class="ml-1" />
					<img v-if="item.final_2" :src="`/images/icon_strength${item.final_2}.png`" width="80" class="ml-1" />
					<img v-if="item.final_3" :src="`/images/icon_strength${item.final_3}.png`" width="80" class="ml-1" />
				</template>
				<template v-slot:item.trainee.sent="{ item }">
					<div v-if="item.trainee.sent" class="text-center">발송완료<br />({{item.trainee.sent}})</div>
				</template>
			</v-data-table>
			<v-divider></v-divider>
			<div class="text-center pt-2 mt-5">
				<v-pagination
						v-model="page"
						:length="pageCount"
				></v-pagination>
			</div>
		</v-card-text>
		<v-card-actions>
			<v-btn class="kb-primary-color ml-6"
				   @click="openMailDialog()"
			>강점 Report 발송</v-btn>
		</v-card-actions>

		<v-dialog v-model="showMailDialog" width="900" style="overflow-x:hidden">
			<v-card>
				<v-card-title>
					강점 REPORT 발송
				</v-card-title>
				<v-card-text>
					<v-layout justify-center column>
						<v-row no-gutters>
							<v-col cols="3">
								<v-subheader>템플릿 선택</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center">
								<v-select :items="templates" item-text="name"
								          v-model="template"
								          label="메일템플릿"
								          outlined dense hide-details
								          return-object
								          @change="onSelectTemplate" />
							</v-col>
							<v-col cols="3" class="mt-5">
								<v-subheader>제목</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center" class="mt-5">
								<v-text-field v-model="subject" hide-details dense outlined></v-text-field>
							</v-col>
							<v-col cols="3">
								<v-subheader>메일 본문</v-subheader>
								<v-layout column class="tags">
									<p style="font-size:12px;">* 아래 치환자를 마우스로 끌어 놓으세요.</p>
									<p><span v-text="`{{url}}`"></span> 접속 주소</p>
									<p><span v-text="`{{training}}`"></span> 교육 과정명</p>
									<p><span v-text="`{{datetime}}`"></span> 교육진행일시</p>
									<p><span v-text="`{{name}}`"></span> 교육생명</p>
									<p><span v-text="`{{email}}`"></span> 이메일주소</p>
									<p><span v-text="`{{pw}}`"></span> 패스워드</p>
									<p><span v-html="`<a href='{{pdf}}'>{{pdf}}</a>`"></span> 결과PDF링크</p>
								</v-layout>
							</v-col>
							<v-col cols="9" align-self="center">
								<div class="tiptap-editor">
									<editor-menu-bar
											:editor="editor"
											v-slot="{ commands, isActive }"
									>
										<div class="menubar">
											<v-layout class="toolbar" align-center>
												<button class="menubar__button" @click="commands.undo" title="되돌리기"><img width="15" src="/images/icons/undo.svg" /></button>
												<button class="menubar__button" @click="commands.redo"><img width="15" src="/images/icons/redo.svg" title="다시실행" /></button>
												<button class="menubar__button ml-5" :class="{ 'is-active': isActive.bold() }" @click="commands.bold" title="굵게"><img width="15" src="/images/icons/bold.svg" /></button>
												<button class="menubar__button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic" title="기울이기"><img width="15" src="/images/icons/italic.svg" /></button>
												<button class="menubar__button" :class="{ 'is-active': isActive.strike() }" @click="commands.strike" title="취소선"><img width="15" src="/images/icons/strike.svg" /></button>
												<button class="menubar__button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline" title="밑줄"><img width="15" src="/images/icons/underline.svg" /></button>
												<!--                                                <button class="menubar__button" :class="{ 'is-active': isActive.code() }" @click="commands.code"><img width="15" src="/images/icons/code.svg" /></button>-->
												<!--                                                <button class="menubar__button" :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph"><img width="15" src="/images/icons/paragraph.svg" /></button>-->
												<button class="menubar__button ml-5" :class="{ 'is-active': isActive.heading({ level: 1 }) }" @click="commands.heading({ level: 1 })" title="제목1">H1</button>
												<button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="commands.heading({ level: 2 })" title="제목2">H2</button>
												<button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 3 }) }" @click="commands.heading({ level: 3 })" title="제목3">H3</button>
												<button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 4 }) }" @click="commands.heading({ level: 4 })" title="제목4">H4</button>
												<button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 5 }) }" @click="commands.heading({ level: 5 })" title="제목5">H5</button>
												<button class="menubar__button ml-5" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list" title="목록"><img width="15" src="/images/icons/ul.svg" /></button>
												<button class="menubar__button" :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list" title="순서목록"><img width="15" src="/images/icons/ol.svg" /></button>
												<button class="menubar__button" :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote" title="인용구"><img width="15" src="/images/icons/quote.svg" /></button>
												<!--                                                <button class="menubar__button" :class="{ 'is-active': isActive.code_block() }" @click="commands.code_block"><img width="15" src="/images/icons/code.svg" /></button>-->
											</v-layout>
										</div>
									</editor-menu-bar>

									<editor-content class="editor__content" :editor="editor" />
								</div>
							</v-col>
						</v-row>
					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-btn color="secondary" @click.native="send()">즉시 발송</v-btn>
					<v-spacer></v-spacer>
					<v-text-field label="예약일자" type="date"
					              v-model="scheduledDate"
					              dense outlined hide-details class="mr-3" />
					<v-text-field label="예약시각" type="time"
					              v-model="scheduledTime"
					              dense outlined hide-details class="mr-3" />
					<v-btn color="secondary" @click.native="send('scheduled')">예약 발송</v-btn>
					<v-btn @click.native="showMailDialog=false">취소</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
	import Buttons from "./Buttons";
	import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
	import {
		Blockquote,
		CodeBlock,
		HardBreak,
		Heading,
		HorizontalRule,
		OrderedList,
		BulletList,
		ListItem,
		TodoItem,
		TodoList,
		Bold,
		Code,
		Italic,
		Link,
		Strike,
		Underline,
		History,
	} from 'tiptap-extensions'
	export default {
		components: {
			Buttons,
			EditorMenuBar,
			EditorContent,
		},
		data() {
			return {
				page: 1,
				pageCount: 0,
				itemsPerPage: 10,
				training: {},
				traineeStrengths: [],
				headers: [
					{text: '순번', sortable: true, value: 'trainee_id'},
					{text: '이름', sortable: true, value: 'trainee.name'},
					{text: '소속', sortable: true, value: 'trainee.division' },
					{text: '1차 선택', sortable: true, value: 'first' },
					{text: '2차 선택', sortable: true, value: 'final' },
					{text: '결과 메일 발송 여부', sortable: true, value: 'trainee.sent' },
				],
				isLoading: true,
				selected: [],
				search: '',

				showMailDialog: false,
				item: null,
				templates: [],
				template: null,
				subject: '',
				body: '',
				scheduledDate: '',
				scheduledTime: '',
				editor: new Editor({
					extensions: [
						new Blockquote(),
						new BulletList(),
						new CodeBlock(),
						new HardBreak(),
						new Heading({ levels: [1, 2, 3] }),
						new HorizontalRule(),
						new ListItem(),
						new OrderedList(),
						new TodoItem(),
						new TodoList(),
						new Link(),
						new Bold(),
						new Code(),
						new Italic(),
						new Strike(),
						new Underline(),
						new History(),
					],
				}),
			}
		},

		mounted() {
			this.training.id = this.$route.params.id

			this.getMailTemplates();
			this.getTraining()
			this.getList()

			$(document.body).off('mousedown', '.tags span').on('mousedown', '.tags span', this.selectText)
			$(document.body).off('mousedown', '.tags a').on('mousedown', '.tags a', e => {
				e.preventDefault()
				e.stopImmediatePropagation()
			})
		},
		beforeDestroy() {
			this.editor.destroy()
		},

		methods: {
			getTraining() {

				this.isLoading = true
				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training/${this.training.id}`).then(response => {
					if (response.data.code === '0000') {
						this.training = response.data.result
					}
				})
			},

			getList() {

				const params = {
					training_id: this.training.id,
				}
				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/strengths`, {params: params}).then(response => {
					if (response.data.code === '0000') {
						this.traineeStrengths = response.data.result

						this.traineeStrengths.forEach(strength => {
							if (strength.trainee.mails && strength.trainee.mails.length > 0) {
								strength.trainee.mails.forEach(mail => {
									if (mail.status === 'sent') {
										strength.trainee.sent = mail.sent_at
									}
								})
							}
						})

						this.selected = this.traineeStrengths
						this.isLoading = false
					}
				})
			},

			start() {
				this.$store.commit('showDialog', {
					type: 'confirm',
					message: '참여 중인 모든 교육생의 화면이 [대표 강점 최종 선택]으로 이동됩니다.\n계속하시겠습니까?',
					okCb: _ => {
						this.$eventBus.$emit('SEND_START_STRENGTH', this.training.id)
					}
				})
			},
			reset() {
				this.$store.commit('showDialog', {
					type: 'confirm',
					message: '참여 중인 모든 교육생의 화면이 [게임 화면]으로 이동됩니다.\n계속하시겠습니까?',
					okCb: _ => {
						const params = {
							training_id: this.training.id,
						}
						this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training/reset`, params).then(response => {
							this.$store.commit('showDialog', {
								type: 'alert',
								message: '교육 과정을 진행중으로 변경했습니다.'
							})
							this.$eventBus.$emit('SEND_BACK_TO_GAME', this.training.id)
						})
					}
				})
			},
			openMailDialog() {
				if (!this.selected.length) {
					this.$store.commit('showDialog', {
						type: 'alert',
						message: '선택된 교육생이 없습니다.'
					})
					return
				}

				this.subject = ''
				this.template = null
				this.editor.setContent('')
				this.scheduledDate = ''
				this.scheduledTime = ''
				this.showMailDialog = true

				this.templates.forEach(template => {
					if (template.is_default) {
						this.template = template
						this.onSelectTemplate(template)
					}
				})
			},
			send(type) {
				this.showDialog = false

				let message = `선택한 ${this.selected.length}명의 교육생에게 메일을 `
				if (type === 'scheduled') {
					if (!this.scheduledDate || !this.scheduledTime) {
						this.$store.commit('showDialog', {
							type: 'alert',
							message: '예약일시가 설정되지 않았습니다.'
						})
						return
					}
					message += `[${this.scheduledDate} ${this.scheduledTime}]에 예약 발송합니다.`
				} else {
					message += '즉시 발송합니다.'
				}

				this.$store.commit('showDialog', {
					type: 'confirm',
					message: message,
					okCb: _ => {
						const trainees = []
						this.selected.forEach(res => {
							trainees.push(res.trainee.id)
						})
						const params = {
							id: this.item.id,
							training_id: this.training.id,
							trainees: trainees,
							type: 'result',
							subject: this.subject,
							body: this.editor.getHTML(),
							is_scheduled: !!type,
						}
						if (this.scheduledDate) {
							params.scheduled = `${this.scheduledDate} ${this.scheduledTime}`
						}

						this.isLoading = true
						this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/send`, params).then(response => {
							if (response.data.code === '0000') {
								this.isLoading = false
								this.getMailTemplates()
								this.getList()

								this.showMailDialog = false

								let message
								if (!!type) {
									message = '메일 발송을 예약했습니다.'
								} else {
									message = '메일 발송 요청을 완료했습니다.'
								}

								this.$store.commit('showSnackbar', {
									message: message,
									color: 'success',
									duration: 3000,
								})
							}
						})
					}
				})

			},
			getMailTemplates() {

				const params = {
					type: 'result'
				}

				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/templates`, {params: params}).then(response => {
					this.templates = response.data.result
				})
			},
			selectText(e) {
				const $newSelection = $(e.currentTarget);
				const selection = window.getSelection();
				const range = document.createRange();
				range.setStartBefore($newSelection.first()[0]);
				range.setEndAfter($newSelection.last()[0]);
				selection.removeAllRanges();
				selection.addRange(range);
			},
			onSelectTemplate(item) {
				this.item = item
				this.subject = item.subject
				this.editor.setContent(item.body)
			},

			downloadExcel() {
				const params = {
					training_id: this.training.id,
				}
				this.axios({
					url: `${process.env.VUE_APP_SERVER_HOST}/api/admin/strengths/excel`,
					params: params,
					method: 'GET',
					responseType: 'blob',
				}).then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${this.training.name}_대표_강점_${moment().format('YYYY-MM-DD')}.xlsx`);
					document.body.appendChild(link);
					link.click();
				});
			},
		}
	}
</script>

<style lang="scss" scoped>

	.tags {
		background-color: #efefef;
		margin-left: -10px;
		margin-right: 10px;
		padding: 5px;

		span {
			background-color: #fff;
			padding: 3px;
			color: #000;
			cursor: move;
		}
	}
</style>
