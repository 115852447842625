var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_vm._v("교육 과정")]),_c('v-card-actions',[_c('v-spacer'),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push('/training/form')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("새 교육 생성 ")],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.trainings,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"loading":_vm.isLoading,"loading-text":"데이터 조회 중.. 잠시만 기다리세요.","hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: ("/training/" + (item.id) + "/trainees")}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.period_minutes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("comma")(item.period_minutes))+"분 ")]}},{key:"item.trainees_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("comma")(item.trainees_count))+"명 ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.editItem(item, index)}}},[_vm._v("편집")]),_c('v-btn',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item, index)}}},[_vm._v("삭제")])],1)]}}])}),_c('v-divider'),_c('div',{staticClass:"text-center pt-2 mt-5"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }