<template>
	<v-app>
		<v-main>
			<v-app-bar dense color="white" flat absolute v-if="appBarShow">
				<v-app-bar-nav-icon @click="$router.back()" small class="ml-0">
					<v-icon>mdi-arrow-left</v-icon>
				</v-app-bar-nav-icon>
				<v-spacer></v-spacer>
				<v-toolbar-title v-text="appBarTitle" class="font-weight-bold"></v-toolbar-title>
				<v-spacer></v-spacer>
			</v-app-bar>
			<transition name="fade" class="fill-height">
				<router-view></router-view>
			</transition>
		</v-main>

		<!-- snackbar -->
		<v-snackbar :timeout="snackbarDuration" :color="snackbarColor" top v-model="showSnackbar">
			{{ snackbarMessage }}
		</v-snackbar>

		<!-- dialog confirm -->
		<v-dialog v-show="showDialog" v-model="showDialog" :persistent="dialogType=='confirm'" absolute max-width="450px">
			<v-card>
				<v-card-title>
					<div class="headline">
						<v-icon v-if="dialogIcon">{{dialogIcon}}</v-icon> {{ dialogTitle }}</div>
				</v-card-title>
				<v-card-text v-html="dialogMessage"></v-card-text>
				<v-card-actions v-if="dialogType=='confirm' || dialogType=='alert'">
					<v-spacer></v-spacer>
					<v-btn color="primary" @click.native="dialogOk">확인</v-btn>
					<v-btn color="" @click.native="dialogCancel" v-show="dialogType=='confirm'">취소</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script>
export default {
	name: 'App',

	components: {},

	data() {
		return {
			transitionName: '',
		}
	},
	computed: {
		showSnackbar: {
			get() {
				return this.$store.getters.showSnackbar;
			},
			set(val) {
				if (!val) this.$store.commit('hideSnackbar');
			}
		},
		snackbarMessage() {
			return this.$store.getters.snackbarMessage;
		},
		snackbarColor() {
			return this.$store.getters.snackbarColor;
		},
		snackbarDuration() {
			return this.$store.getters.snackbarDuration;
		},


		// dialog
		showDialog: {
			get() {
				return this.$store.getters.showDialog;
			},
			set(val) {
				if (!val) this.$store.commit('hideDialog');
			}
		},
		dialogType() {
			return this.$store.getters.dialogType
		},
		dialogTitle() {
			return this.$store.getters.dialogTitle
		},
		dialogMessage() {
			return this.$store.getters.dialogMessage.replace(/\n/g, '<br />')
		},
		dialogIcon() {
			return this.$store.getters.dialogIcon
		},

		appBarShow() {
			return this.$store.getters.appBarShow
		},

		appBarTitle() {
			return this.$store.getters.appBarTitle
		},
	},

	beforeMount() {
		const self = this

		// this.axios.defaults.withCredentials = true
		this.axios.interceptors.request.use(function(config) {
			const token = self.$cookie.get('admin_token')
			if (token) {
				config.headers.token = token
			}
			return config;
		}, function(error) {
			// Do something with request error
			console.log('axios.request.error', error)
			return Promise.reject(error);
		});

		// Add a response interceptor
		this.axios.interceptors.response.use(function(response) {
			if (self.$route.path !== '/login') {
			    if (response.data.code === '0000') {
			        self.$cookie.set('admin_token', self.$cookie.get('admin_token'), { expires: '2h'})
				} else if (response.data.code > '9000') {
					self.$store.commit('showSnackbar', {
						message: response.data.message,
						color: 'error',
						duration: 3000
					});
				}
				if (['9901', '9902'].indexOf(response.data.code) > -1) {
					self.$router.push('/login')
				}
			}
			return response;
		}, function(error) {
			self.$store.commit('showSnackbar', {
				message: error,
				color: 'error',
				duration: 3000
			});
			return Promise.reject(error);
		});
	},

	mounted() {},
	methods: {


		dialogOk() {
			this.$store.commit('dialogOk');
		},
		dialogCancel() {
			this.$store.commit('dialogCancel');
		},
	}

};
</script>

<style>
* {
	font-family: KBFGDisplayM
}

.kb-color {
	color: #cea427 !important;
	caret-color: #cea427 !important;
}

.kb-primary-color {
	color: #ffffff !important;
	caret-color: #ffffff !important;
	background-color: #60584C !important;
}

.fade-enter-active,
.fade-leave-active {
	transition-property: opacity;
	transition-duration: .25s;
}

.fade-enter-active {
	transition-delay: .25s;
}

.fade-enter,
.fade-leave-active {
	opacity: 0
}

th {
	font-size: 15px !important;
}

.ProseMirror {
	height: 300px;
	overflow-y: auto;
	padding: 5px;
}

.ProseMirror p {
	margin-bottom: 10px !important;
}
</style>

