<template>
	<v-card width="100%" class="d-flex flex-column">
		<v-card-actions class="pb-0">
			<buttons />
		</v-card-actions>
		<v-card-actions>
			<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="도움말 제목"
					single-line
					hide-details
					class="ml-5"
			></v-text-field>
			<v-spacer></v-spacer>
			<v-btn color="kb-primary-color"
			       class="mr-5"
			       @click="addItem()"
			>새 도움말 생성</v-btn>
		</v-card-actions>
		<v-card-text>

			<v-data-table
					item-key="id"
					:headers="headers"
					:items="helps"
					:items-per-page="itemsPerPage"
					:page.sync="page"
					:loading="isLoading"
					:search="search"
					loading-text="데이터 조회 중.. 잠시만 기다리세요."
					hide-default-footer
					@page-count="pageCount = $event"
			>
				<template v-slot:item.action="{ item }">
					<div class="text-right">
						<v-btn @click="editItem(item)" color="primary" class="mr-1">편집</v-btn>
						<v-btn @click="deleteItem(item)" color="error">삭제</v-btn>
					</div>
				</template>
			</v-data-table>
			<v-divider></v-divider>
			<div class="text-center pt-2 mt-5">
				<v-pagination
						v-model="page"
						:length="pageCount"
				></v-pagination>
			</div>
		</v-card-text>


		<v-dialog v-if="item" v-model="showDialog" width="900" style="overflow-x:hidden">
			<v-card>
				<v-card-title>
					도움말 {{ item.id ? '수정' : '추가'}}
				</v-card-title>
				<v-card-text>
					<v-layout justify-center column>
						<v-row no-gutters>
							<v-col cols="3">
								<v-subheader>제목</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center">
								<v-text-field v-model="item.title" outlined dense hide-details />
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="3">
								<v-subheader>내용</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center" class="pt-5">
								<v-textarea v-model="item.body"
								            full-width
								            rows="10"
								            outlined
								></v-textarea>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="3">
								<v-subheader>첨부</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center">
								<v-file-input
										v-model="file"
										class=""
										label="첨부 파일 (이미지, 동영상)"
										truncate-length="40"
										show-size
										accept="image/*,video/*"
										@mousedown="file=null"
										@change="upload"
								></v-file-input>
								<v-progress-circular
										v-if="uploading"
										indeterminate
										color="primary"
								></v-progress-circular>
								<div v-if="item.file" class="attachment">
									<img v-if="item.file_type==='image'" :src="item.file" />
									<video v-if="item.file_type==='video'" :src="item.file" controls />
								</div>
							</v-col>
						</v-row>
					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="kb-primary-color" @click.native="save">{{ item.id ? '수정' : '추가'}}</v-btn>
					<v-btn @click.native="showDialog=false">취소</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-card>
</template>

<script>
	import Buttons from "./Buttons";
	export default {
		components: {
			Buttons
		},
		data() {
			return {
				training: {},
				selected: null,
				helps: [],
				item: null,

				showDialog: false,

				search: '',
				page: 1,
				pageCount: 0,
				itemsPerPage: 10,
				headers: [
					{text: '#', sortable: true, value: 'id'},
					{text: '제목', sortable: true, value: 'title' },
					{text: '', sortable: true, value: 'action' },
				],
				isLoading: true,

				file: null,
				uploading: false,

			}
		},

		mounted() {
			this.getList()
		},

		methods: {

			getList() {

				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/helps`).then(response => {
					if (response.data.code === '0000') {
						this.helps = response.data.result
						this.isLoading = false
					}
				})
			},

			addItem() {
				this.item = {}
				this.file = null
				this.showDialog = true
			},

			editItem(item) {
				this.item = item
				this.file = null
				this.showDialog = true
			},

			deleteItem(item) {
				this.$store.commit('showDialog', {
					type: 'confirm',
					message: '도움말을 삭제하시겠습니까?',
					okCb: _ => {
						this.axios.delete(`${process.env.VUE_APP_SERVER_HOST}/api/admin/helps/${item.id}`).then(response => {
							this.getList()
						})
					}
				})
			},

			save() {

				this.showDialog = false

				this.isLoading = true
				this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/helps`, this.item).then(response => {
					if (response.data.code === '0000') {
						this.isLoading = false
						this.getList()

						this.$store.commit('showSnackbar', {
							message: '수정했습니다.',
							color: 'success',
							duration: 3000,
						})
					}
				})
			},

			upload() {

				const self = this

				if (!this.file) return

				const formData = new FormData()
				formData.append('file', this.file)

				const options = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}

				this.uploading = true
				this.item.file = null

				this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/helps/upload`, formData, options).then(response => {
					self.item.file = response.data.result.url
					if (this.file.type && this.file.type.split('/').length > 0) {
						self.item.file_type = this.file.type.split('/')[0]
					}
					self.uploading = false
				})
			},
		}
	}
</script>
<style lang="scss" scoped>
	.attachment {
		&>* {
			max-width: 200px;
			max-height: 200px;
		}
	}
</style>

<style>
	.highlight {
		font-weight: 600;
		color: #FEBD36;
	}
	.hint {
		color: #bd2130;
	}
</style>
