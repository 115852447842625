<template>
    <v-main class="fill-height pt-16">
        <v-app-bar color="default" flat fixed>
<!--            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>-->
            <v-toolbar-title class="app-title"><v-img src="/images/logo.png" max-height="40" contain /></v-toolbar-title>
            <v-spacer></v-spacer>
            <button class="btn btn-primary" style="width:170px" @click="moveTo('/training')">교육 과정 생성/선택</button>
            <button class="btn btn-primary ml-3" style="width:170px" @click="moveTo('/manage/hints')">도움말&단서&Quiz</button>
            <v-spacer></v-spacer>
            <button class="btn btn-primary mr-2" style="" @click="moveTo('/manage/email/template')">이메일 템플릿</button>
            <button class="btn btn-secondary" @click="logout()">로그아웃</button>
        </v-app-bar>
        <v-layout fill-height class="pt-6 pb-6">

            <router-view name="main" class="main"></router-view>

        </v-layout>
    </v-main>
</template>
<script>
    import io from 'socket.io-client';

    export default {
    	data() {
    		return {
			    drawer: false,
			    group: null,
			    messages: {},
            }
        },
        mounted() {
            // this.socket = io(process.env.VUE_APP_SOCKET_HOST)
            this.socket = io(process.env.VUE_APP_SOCKET_HOST, {
                transports: ['websocket'],
                secure: true,
            })
            this.setSocketListener()

            // this.messages = JSON.parse(localStorage.getItem('messages') || '[]')

    		this.$eventBus.$on('MOVE_TO', this.moveTo)
        },
	    beforeCreate() {
		    if (!this.$cookie.get('admin_token')) {
		    	this.$router.replace('/')
			    return
		    }
	    },
	    beforeMount() {
            this.getMessages()
        },
        methods: {
    		logout() {
    			this.$cookie.delete('admin_token')
                this.$router.push('/')
            },
            moveTo(path, type) {
	            if (this.$route.path !== path) {
	                if (type === 'replace') {
                        this.$router.replace(path)
                    } else {
                        this.$router.push(path)
                    }
                }
            },
            setSocketListener() {
                this.socket.on('connect', data => {
                    console.log('connected')

                    this.socket.emit('joinAdmin')
                })
                this.socket.on('disconnect', _ => {
                    console.log('disconnected')
                })

                this.socket.on('message', (training_id, trainee_id, message) => {
                    if (!this.messages[training_id]) this.messages[training_id] = []

                    this.messages[training_id].push({
                        training_id: training_id,
                        trainee_id: trainee_id,
                        from_admin: false,
	                    message: message
                    })
                    localStorage.setItem('messages', JSON.stringify(this.messages))
	                this.$eventBus.$emit('RECEIVED_MESSAGE', trainee_id)
                })

                this.socket.on('admin-message', message => {
                	console.log('received admin-message')
                	this.getMessages()
                    this.$eventBus.$emit('RECEIVED_MESSAGE')
                })

                this.$eventBus.$on('SEND_MESSAGE', (training_id, trainee_id, message)=> {
                    if (!this.messages[training_id]) this.messages[training_id] = []

                    this.messages[training_id].push({
                        training_id: training_id,
	                    trainee_id: trainee_id,
	                    from_admin: true,
                        message: message
                    })
                    localStorage.setItem('messages', JSON.stringify(this.messages))
                    this.socket.emit('admin-message', training_id, trainee_id, message)
                })

                this.$eventBus.$on('SEND_START_STRENGTH', id => {
                    this.socket.emit('start-strength', id)
                })

                this.$eventBus.$on('SEND_FINISH_GAME', id => {
                    this.socket.emit('finish-game', id)
                })

                this.$eventBus.$on('SEND_BACK_TO_GAME', id => {
                	this.socket.emit('back-to-game', id)
                })

                this.$eventBus.$on('SEND_POINT', (training_id, trainee_id, point) => {
                	this.socket.emit('send-point', training_id, trainee_id, point)
                })

                this.$eventBus.$on('RESET', ids => {
                    this.socket.emit('reset', ids)
                })
            },

            async getMessages() {
                const response = await this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/messages`)
                if (response.data.code === '0000') {
                    response.data.result.forEach(message => {
                        if (!this.messages[message.training_id]) {
                            this.messages[message.training_id] = []
                        }

                        this.messages[message.training_id].push(message)
                    })

                    localStorage.setItem('messages', JSON.stringify(this.messages))
                }
            },
        }
    }
</script>
<style scoped>
    .app-title {
        font-family: KBFGDisplayB;
        color: rgba(0, 0, 0, 0.54) !important;
    }

    .main {
        max-width: 1280px;
        margin: 0 auto;
    }
</style>

<style>
    .btn-primary {
        color: #60584c !important;
        border: 2px solid #60584C !important;
        background-color: #ffbc00 !important;
    }
    .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
        color: #fff !important;
        background-color: #60584C !important;
        border: 2px solid #60584C !important;

    }
    .btn-secondary {
        color: #ffffff !important;
        border: 2px solid #60584C!important;
        background-color: #60584c !important;
    }
    .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .open>.dropdown-toggle.btn-secondary {
        color: #fff !important;
        background-color: #464038 !important;
        border: 2px solid #464038 !important;

    }
    .btn:focus,.btn:active {
        outline: none !important;
        box-shadow: 0px 0px 1px 2px #a59a8f !important;
    }
    .btn {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }
    .btn:focus i, .btn:active i, .btn:hover i {
        color: #ffffff !important;
    }
</style>
