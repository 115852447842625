<template>
    <v-card width="100%">
        <v-card-title class="list_title">
            <v-btn icon small class="mr-2" @click="$router.back()">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            {{ training.name}}
        </v-card-title>
        <v-card-actions class="pt-0 pb-0">
            <buttons :training="training" />
        </v-card-actions>
	    <v-card-subtitle v-show="results.length > 0" class="summary">
            <div>
                <v-icon color="purple darken-4" class="mr-2">mdi-account-multiple-check</v-icon>평균 강점 매칭률
                {{ Math.round(totalMatched / (results.length * 8) * 1000) / 10 }}%
                <v-icon color="orange darken-4" class="ml-5 mr-2">mdi-format-list-checks</v-icon>평균 미션 달성도
                {{ Math.round(totalMissionCompleted / (results.length * 21) * 1000) / 10 }}%
                <v-icon color="blue-grey darken-1" class="ml-5 mr-2">mdi-calculator</v-icon>평균 획득 포인트
                {{ totalPoints / results.length | formatNumber }}
            </div>
        </v-card-subtitle>
        <v-card-actions>
            <v-btn @click="downloadExcel">Excel 다운로드</v-btn>
        </v-card-actions>
        <v-card-text>
            <v-data-table
                v-model="selected"
                item-key="id"
                :headers="headers"
                :items="results"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                :loading="isLoading"
                :search="search"
                loading-text="데이터 조회 중.. 잠시만 기다리세요."
                hide-default-footer
                @page-count="pageCount = $event"
            >
                <template v-slot:item.matched="{ item }">
                    {{ Math.round(item.matched / 8 * 1000) / 10 }}%
                </template>
                <template v-slot:item.missionCompleted="{ item }">
                    {{ Math.round(item.missionCompleted / 21 * 1000) / 10 }}%
                </template>
                <template v-slot:item.points="{ item }">
                    {{ item.points | formatNumber }}
                </template>
            </v-data-table>
            <v-divider></v-divider>
            <div class="text-center pt-2 mt-5">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    ></v-pagination>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import Buttons from "./Buttons";
    export default {
    	components: {
    		Buttons
        },
	    data() {
		    return {
                training: {},
                results: [],
		    	page: 1,
                pageCount: 0,
			    itemsPerPage: 10,
			    headers: [
                    {text: '순위', sortable: true, value: 'ranking'},
				    {text: '이름', sortable: true, value: 'name'},
				    {text: '소속', sortable: true, value: 'division' },
                    {text: '강점 매칭률', sortable: true, value: 'matched' },
                    {text: '미션 달성도', sortable: true, value: 'missionCompleted' },
                    {text: 'Point 합계', sortable: true, value: 'points' },
                    {text: '등급', sortable: true, value: 'class' },
			    ],
                isLoading: true,
                selected: [],
                search: '',
                showDialog: false,
                totalMatched: 0,
                totalMissionCompleted: 0,
                totalPoints: 0,

            }
        },
        watch: {
            showDialog() {
                this.file = null
            }
        },
	    filters: {
    		formatNumber(val) {
                return new Intl.NumberFormat().format(val)

            }
        },
        mounted() {

	    	this.training.id = this.$route.params.id

            this.getList()
        },
        methods: {
            getList() {

                this.isLoading = true
                this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training/${this.training.id}`).then(response => {
                    if (response.data.code === '0000') {
                        this.training = response.data.result
                        this.isLoading = false
                    }
                })

                const params = {
                    training_id: this.training.id,
                }
                this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/userdata`, {params: params}).then(response => {
                    if (response.data.code === '0000') {

                    	const results = []

                        let ranking = 0

                        response.data.result.forEach(userData => {
                            ranking++
                            const result = {
                                trainee_id: userData.trainee.id,
                                name: userData.trainee.name,
                                division: userData.trainee.division,
	                            ranking: ranking,
                                matched: 0,
                                missionCompleted: 0,
	                            points: 0,
                            }

                    		const matched = JSON.parse(userData.strength_completed)
                            result.matched = Object.keys(matched).length

                            const missionCompleted = JSON.parse(userData.mission_completed)
	                        Object.keys(missionCompleted).forEach(level => {
	                        	Object.keys(missionCompleted[level]).forEach(id => {
	                        		if (missionCompleted[level][id]) {
	                        		    result.missionCompleted++
                                    }
                                })
                            })

                            result.points = userData.points

                            if (result.points >= 700) {
                                result.class = 'S'
                            } else if (result.points >= 550) {
                                result.class = 'A'
                            } else if (result.points >= 400) {
                                result.class = 'B'
                            } else {
                                result.class = 'C'
                            }


                            this.totalMatched += result.matched
	                        this.totalMissionCompleted += result.missionCompleted
                            this.totalPoints += result.points

                            results.push(result)
                        })

	                    this.results = results
                    }
                })
            },

            downloadExcel() {
                const params = {
                    training_id: this.training.id,
                }
                this.axios({
                    url: `${process.env.VUE_APP_SERVER_HOST}/api/admin/userdata/excel`,
                    params: params,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.training.name}_게임결과_${moment().format('YYYY-MM-DD')}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                });
            },

            moveTo(path) {
	    		this.$eventBus.$emit('MOVE_TO', path)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .list_title { height: 64px;}

    .summary {
        text-align: center;
	    font-weight: 600;
        font-size: 17px;
        display: flex;
        justify-content: center;

        &>div {
            padding: 5px 20px;
	        border: 2px solid #efefef;
            border-radius: 10px;
        }
    }
</style>
