<template>
	<v-card width="100%">
		<v-card-title>이메일 템플릿 관리</v-card-title>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="secondary" @click="addItem">템플릿 추가</v-btn>
		</v-card-actions>
		<v-card-text>
			<v-data-table
					item-key="id"
					:headers="headers"
					:items="templates"
					:items-per-page="itemsPerPage"
					:page.sync="page"
					:loading="isLoading"
					loading-text="데이터 조회 중.. 잠시만 기다리세요."
					hide-default-footer
					@page-count="pageCount = $event"
			>
				<template v-slot:item.is_default="{ item }">
					<div class="text-center">
						<v-icon color="green" v-if="item.is_default">mdi-check-circle-outline</v-icon>
					</div>
				</template>
				<template v-slot:item.type="{ item }">
					{{ item.type === 'invitation' ? '안내메일' : '결과메일'}}
				</template>
				<template v-slot:item.created_at="{ item }">
					{{ item.created_at | moment('YYYY-MM-DD HH:mm:ss')}}
				</template>
				<template v-slot:item.action="{ item }">
					<v-btn color="secondary" small @click="editItem(item)">편집</v-btn>
					<v-btn color="error" small class="ml-2" @click="deleteItem(item)">삭제</v-btn>
				</template>
			</v-data-table>
			<v-divider></v-divider>
			<div class="text-center pt-2 mt-5">
				<v-pagination
						v-model="page"
						:length="pageCount"
				></v-pagination>
			</div>
		</v-card-text>

		<v-dialog v-model="showDialog" width="900" style="overflow-x:hidden">
			<v-card>
				<v-card-title>
					템플릿 {{ item.id ? '수정' : '추가'}}
				</v-card-title>
				<v-card-text>
					<v-layout justify-center column>
						<v-row no-gutters>
							<v-col cols="3">
								<v-subheader>메일 유형</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center">
								<v-select v-model="type" :items="types" dense outlined></v-select>
							</v-col>
							<v-col cols="3">
								<v-subheader>템플릿 명</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center">
								<v-text-field v-model="name" hide-details dense outlined></v-text-field>
							</v-col>
							<v-col cols="3">
								<v-subheader class="mt-3">기본 템플릿</v-subheader>
							</v-col>
							<v-col cols="9">
								<v-checkbox v-model="is_default" persistent-hint hint="기본 템플릿으로 설정된 경우 자동으로 발송되는 메일 양식으로 사용됩니다." label="기본 템플릿으로 지정" />
							</v-col>
							<v-col cols="3" class="mt-5">
								<v-subheader>제목</v-subheader>
							</v-col>
							<v-col cols="9" align-self="center" class="mt-5">
								<v-text-field v-model="subject" hide-details dense outlined></v-text-field>
							</v-col>
							<v-col cols="3">
								<v-subheader>메일 본문</v-subheader>
								<v-layout column class="tags">
									<p style="font-size:12px;">* 아래 치환자를 마우스로 끌어 놓으세요.</p>
									<p><span v-text="`{{url}}`"></span> 접속 주소</p>
									<p><span v-text="`{{training}}`"></span> 교육 과정명</p>
									<p><span v-text="`{{datetime}}`"></span> 교육진행일시</p>
									<p><span v-text="`{{name}}`"></span> 교육생명</p>
									<p><span v-text="`{{email}}`"></span> 이메일주소</p>
									<p><span v-text="`{{pw}}`"></span> 패스워드</p>
									<p><span v-text="`{{pdf}}`"></span> 결과PDF링크</p>
								</v-layout>
							</v-col>
							<v-col cols="9" align-self="center">
								<div class="tiptap-editor">
									<editor-menu-bar
											:editor="editor"
											v-slot="{ commands, isActive }"
									>
										<div class="menubar">
											<v-layout class="toolbar" align-center>
												<button class="menubar__button" @click="commands.undo" title="되돌리기"><img width="15" src="/images/icons/undo.svg" /></button>
												<button class="menubar__button" @click="commands.redo"><img width="15" src="/images/icons/redo.svg" title="다시실행" /></button>
												<button class="menubar__button ml-5" :class="{ 'is-active': isActive.bold() }" @click="commands.bold" title="굵게"><img width="15" src="/images/icons/bold.svg" /></button>
												<button class="menubar__button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic" title="기울이기"><img width="15" src="/images/icons/italic.svg" /></button>
												<button class="menubar__button" :class="{ 'is-active': isActive.strike() }" @click="commands.strike" title="취소선"><img width="15" src="/images/icons/strike.svg" /></button>
												<button class="menubar__button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline" title="밑줄"><img width="15" src="/images/icons/underline.svg" /></button>
												<!--                                                <button class="menubar__button" :class="{ 'is-active': isActive.code() }" @click="commands.code"><img width="15" src="/images/icons/code.svg" /></button>-->
												<!--                                                <button class="menubar__button" :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph"><img width="15" src="/images/icons/paragraph.svg" /></button>-->
												<button class="menubar__button ml-5" :class="{ 'is-active': isActive.heading({ level: 1 }) }" @click="commands.heading({ level: 1 })" title="제목1">H1</button>
												<button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="commands.heading({ level: 2 })" title="제목2">H2</button>
												<button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 3 }) }" @click="commands.heading({ level: 3 })" title="제목3">H3</button>
												<button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 4 }) }" @click="commands.heading({ level: 4 })" title="제목4">H4</button>
												<button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 5 }) }" @click="commands.heading({ level: 5 })" title="제목5">H5</button>
												<button class="menubar__button ml-5" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list" title="목록"><img width="15" src="/images/icons/ul.svg" /></button>
												<button class="menubar__button" :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list" title="순서목록"><img width="15" src="/images/icons/ol.svg" /></button>
												<button class="menubar__button" :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote" title="인용구"><img width="15" src="/images/icons/quote.svg" /></button>
												<!--                                                <button class="menubar__button" :class="{ 'is-active': isActive.code_block() }" @click="commands.code_block"><img width="15" src="/icons/code.svg" /></button>-->
											</v-layout>
										</div>
									</editor-menu-bar>

									<editor-content class="editor__content" :editor="editor" />
								</div>
							</v-col>
						</v-row>
					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click.native="save">{{item.id ? '수정' : '추가'}}</v-btn>
					<v-btn @click.native="showDialog=false">취소</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
	import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
	import {
		Blockquote,
		CodeBlock,
		HardBreak,
		Heading,
		HorizontalRule,
		OrderedList,
		BulletList,
		ListItem,
		TodoItem,
		TodoList,
		Bold,
		Code,
		Italic,
		Link,
		Strike,
		Underline,
		History,
	} from 'tiptap-extensions'
	export default {
		components: {
			EditorMenuBar,
			EditorContent,
		},
		data() {
			return {
				page: 1,
				pageCount: 0,
				itemsPerPage: 100,
				headers: [
					{text: '#', sortable: true, value: 'id'},
					{text: '유형', sortable: true, value: 'type'},
					{text: '기본템플릿', sortable: true, value: 'is_default'},
					{text: '템플릿명', sortable: true, value: 'name' },
					{text: '제목', sortable: true, value: 'subject' },
					{text: '', sortable: true, value: 'action' },
				],
				isLoading: true,
				selected: [],
				templates: [],

				item: {},
				types: [
					{text: '안내메일', value: 'invitation'},
					{text: '결과메일', value: 'result'},
				],
				type: '',
				name: '',
				subject: '',
				body: '',
				is_default: false,

				showDialog: false,

				editor: new Editor({
					extensions: [
						new Blockquote(),
						new BulletList(),
						new CodeBlock(),
						new HardBreak(),
						new Heading({ levels: [1, 2, 3] }),
						new HorizontalRule(),
						new ListItem(),
						new OrderedList(),
						new TodoItem(),
						new TodoList(),
						new Link(),
						new Bold(),
						new Code(),
						new Italic(),
						new Strike(),
						new Underline(),
						new History(),
					],
				}),
			}
		},

		beforeDestroy() {
			this.editor.destroy()
		},
		mounted() {
			this.getList();
			$(document.body).off('mousedown', '.tags span').on('mousedown', '.tags span', this.selectText)
		},
		methods: {
			getList() {

				this.isLoading = true
				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/templates`).then(response => {
					this.templates = response.data.result

					this.isLoading = false
				})
			},

			save() {
				if (!this.type) {
					alert('메일 유형을 선택해주세요.')
					return
				}

				if (!this.name) {
					alert('템플릿명을 선택해주세요.')
					return
				}

				if (!this.subject) {
					alert('제목을 입력해주세요.')
					return
				}

				let body = this.editor.getHTML()
				body = body.replace(/<blockquote>/g, "<blockquote style=\"border-left: 3px solid rgba(0, 0, 0, 0.1);color: rgba(0, 0, 0, 0.8);padding-left: 0.8rem;font-style: italic;\" class='gmail_quote'>")

				if (!body.replace(/<[^>]+>/g, '').replace(/\s/g, '')) {
					alert('메일본문을 입력해주세요.')
					return
				}

				this.showDialog = false

				const params = {
					id: this.item.id,
					type: this.type,
					is_default: this.is_default,
					template_name: this.item.template_name,
					name: this.name,
					subject: this.subject,
					body: body,
				}

				this.isLoading = true
				this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/templates`, params).then(response => {
					if (response.data.code === '0000') {
						this.isLoading = false
						this.getList()

						this.$store.commit('showSnackbar', {
							message: '수정했습니다.',
							color: 'success',
							duration: 3000,
						})
					}
				})
			},

			addItem() {
				this.item = {}
				this.type = ''
				this.is_default = false
				this.name = ''
				this.subject = ''
				this.body= ''
				this.editor.setContent('')

				this.showDialog = true
			},

			editItem(item) {
				this.item = item
				this.type = item.type
				this.is_default = item.is_default
				this.name = item.name
				this.subject = item.subject
				this.body= item.body
				this.editor.setContent(item.body)

				this.showDialog = true
			},

			deleteItem(item) {
				this.$store.commit('showDialog', {
					message: '발송 중인 템플릿을 삭제하면 메일이 정상적으로 발송되지 않습니다.\n\n계속하시겠습니까?',
					okCb: _ => {
						// delete
						this.axios.delete(`${process.env.VUE_APP_SERVER_HOST}/api/admin/templates/${item.id}`).then(response => {
							this.getList()
						})
					}
				})
			},
			selectText(e) {
				const $newSelection = $(e.currentTarget);
				const selection = window.getSelection();
				const range = document.createRange();
				range.setStartBefore($newSelection.first()[0]);
				range.setEndAfter($newSelection.last()[0]);
				selection.removeAllRanges();
				selection.addRange(range);
			}
		}
	}
</script>

<style lang="scss" scoped>

	.tags {
		background-color: #efefef;
		margin-left: -10px;
		margin-right: 10px;
		padding: 5px;

		span {
			background-color: #fff;
			padding: 3px;
			color: #000;
			cursor: move;
		}
	}


</style>
