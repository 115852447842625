import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/login',
        },
        {
            path: '/login',
            name: 'Login',
            component: require('../components/login/Login').default
        },
        {
            path: '/main',
            name: 'Main',
            component: require('../components/main/Main').default,
            children: [
                {
                    path: '/training',
                    name: 'TrainingList',
                    components: {
                        main: require('../components/trainings/TrainingList').default
                    }
                },
                {
                    path: '/training/form',
                    name: 'TraineeForm',
                    components: {
                        main: require('../components/trainings/TrainingForm').default
                    }
                },
                {
                    path: '/training/:id/form',
                    name: 'TraineeForm',
                    components: {
                        main: require('../components/trainings/TrainingForm').default
                    }
                },
                {
                    path: '/training/:id/trainees',
                    name: 'TraineeList',
                    components: {
                        main: require('../components/trainings/TraineeList').default
                    }
                },
                {
                    path: '/training/:id/trainee/form',
                    name: 'TraineeForm',
                    components: {
                        main: require('../components/trainings/TraineeForm').default
                    }
                },
                {
                    path: '/training/:id/trainee/:trainee_id/form',
                    name: 'TraineeForm',
                    components: {
                        main: require('../components/trainings/TraineeForm').default
                    }
                },
                {
                    path: '/training/:id/results',
                    name: 'TrainingResults',
                    components: {
                        main: require('../components/trainings/TrainingResult').default
                    }
                },
                {
                    path: '/training/:id/strengths',
                    name: 'Strengths',
                    components: {
                        main: require('../components/trainings/Strength').default
                    }
                },
                {
                    path: '/training/:id/point',
                    name: 'Point',
                    components: {
                        main: require('../components/trainings/Point').default
                    }
                },
                {
                    path: '/training/:id/message',
                    name: 'Message',
                    components: {
                        main: require('../components/trainings/Message').default
                    }
                },
                {
                    path: '/manage/hints',
                    name: 'Hints',
                    components: {
                        main: require('../components/manage/Hints').default
                    }
                },
                {
                    path: '/manage/helps',
                    name: 'Helps',
                    components: {
                        main: require('../components/manage/Helps').default
                    }
                },
                {
                    path: '/manage/quiz',
                    name: 'Quiz',
                    components: {
                        main: require('../components/manage/Quiz').default
                    }
                },
                {
                    path: '/manage/email/template',
                    name: 'EmailTemplate',
                    components: {
                        main: require('../components/manage/EmailTemplate').default
                    }
                },



                {
                    path: '/qr_generator',
                    name: 'QrGenerator',
                    components: {
                        main: require('../components/manage/QrGenerator').default
                    }

                }
            ]
        },
    ]
})
