<template>
    <ul class="nav nav-pills mb-3 d-flex" role="tablist" style="width: 100%;">
        <li class="nav-item mr-1">
            <a class="nav-link" data-toggle="pill" role="tab" aria-selected="true"
               :class="$route.path.endsWith('trainees') ? 'active' : ''"
               @click="moveTo(`/training/${training.id}/trainees`)"
            >교육생 정보</a>
        </li>
        <li class="nav-item mr-1">
            <a class="nav-link" data-toggle="pill" role="tab" aria-selected="false"
               :class="$route.path.endsWith('results') ? 'active' : ''"
               @click="moveTo(`/training/${training.id}/results`)"
            >게임 결과</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-toggle="pill" role="tab" aria-selected="false"
               :class="$route.path.endsWith('strengths') ? 'active' : ''"
               @click="moveTo(`/training/${training.id}/strengths`)"
            >강점 관리</a>
        </li>
        <v-spacer></v-spacer>
        <li class="nav-item mr-1">
            <a class="nav-link warning" data-toggle="pill" role="tab" aria-selected="false"
               @click="finishGame"
            >교육생 전체 게임 진행 종료</a>
        </li>
        <li class="nav-item mr-1">
            <a class="nav-link" data-toggle="pill" role="tab" aria-selected="false"
               :class="$route.path.endsWith('point') ? 'active' : ''"
               @click="moveTo(`/training/${training.id}/point`)"
            >Point 지급</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-toggle="pill" role="tab" aria-selected="false"
               :class="$route.path.endsWith('message') ? 'active' : ''"
               @click="moveTo(`/training/${training.id}/message`)"
            >메시지</a>
        </li>
    </ul>
</template>

<script>
    export default {
    	props: [
    		'training',
        ],
	    methods: {
		    moveTo(path) {
			    this.$eventBus.$emit('MOVE_TO', path, 'replace')
		    },


            finishGame() {
                this.$store.commit('showDialog', {
                    type: 'confirm',
                    message: '정말 게임 진행을 종료하시겠습니까?',
                    okCb: _ => {
                        this.$eventBus.$emit('SEND_FINISH_GAME', this.training.id)
                    }
                })
            },
	    },
    }
</script>

<style lang="scss" scoped>
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #60584C !important;
    }
    .nav-pills .nav-link.warning {
        background-color: #fb8c00 !important;

    }
    .nav-pills .nav-link {
        background-color: #a79985 !important;
        color: #ffffff;
    }
</style>
