<template>
    <ul class="nav nav-pills mb-3 d-flex" role="tablist" style="width: 100%;">
        <li class="nav-item mr-1">
            <a class="nav-link" data-toggle="pill" role="tab" aria-selected="true"
               :class="$route.path.endsWith('hints') ? 'active' : ''"
               @click="moveTo(`/manage/hints`)"
            >단서 수정</a>
        </li>
        <li class="nav-item mr-1">
            <a class="nav-link" data-toggle="pill" role="tab" aria-selected="false"
               :class="$route.path.endsWith('quiz') ? 'active' : ''"
               @click="moveTo(`/manage/quiz`)"
            >Quiz 추가/수정</a>
        </li>
        <li class="nav-item mr-1">
            <a class="nav-link" data-toggle="pill" role="tab" aria-selected="false"
               :class="$route.path.endsWith('helps') ? 'active' : ''"
               @click="moveTo(`/manage/helps`)"
            >도움말 추가/수정</a>
        </li>
    </ul>
</template>

<script>
    export default {
    	props: [
        ],
	    methods: {
		    moveTo(path) {
			    this.$eventBus.$emit('MOVE_TO', path, 'replace')
		    }
	    },
    }
</script>

<style lang="scss" scoped>
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #60584C !important;
    }
    .nav-pills .nav-link {
        background-color: #a79985 !important;
        color: #ffffff;
    }
</style>
