<template>
    <v-layout column>
        <div>
            <label>접두어</label>
            <v-text-field outlined v-model="prefix" />

            <label>생성규칙</label>
            <v-text-field outlined v-model="rules" />

            <label>생성갯수</label>
            <v-text-field outlined type="number" v-model="count" />
            <v-btn @click="generate">생성</v-btn>
        </div>
        <v-divider></v-divider>


        <v-layout style="width: 100%;" wrap>
            <span
                style="padding: 10px;"
                v-for="code in codes"
            >
                <qrcode-vue :value="code" size="200" label="H" renderAs="svg" class="qr" />
                {{ code }}
            </span>
        </v-layout>
    </v-layout>
</template>

<script>
    import QrcodeVue from 'qrcode.vue'

    export default {
        components: {
            QrcodeVue
        },
        data() {
            return {
                codes: [],
                prefix: 'KB',
                rules: '23456789',
                count: 10,
            }
        },
        mounted() {
            $(document.body).on('click', '.qr', event => {
                const target = event.currentTarget

                if ($(target).hasClass('qr')) {

                    const name = $(target).parent().text().trim()

                    let svg = $(target).children('svg').get(0)

                    let serializer = new XMLSerializer();
                    let source = serializer.serializeToString(svg);

                    if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
                        source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
                    }
                    if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
                        source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
                    }

                    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

                    let url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);

                    var downloadLink = document.createElement("a");
                    downloadLink.href = url;
                    downloadLink.download = name
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                
                }
            })
        },
    	props: [
        ],
	    methods: {
            generate() {

                this.codes = []
                for (let i = 0; i < this.count; i++) {
                    const code = this.prefix + this.randomChar(6)

                    if (this.codes.indexOf(code) > -1) {
                        i--
                        continue
                    }

                    this.codes.push(code)
                }
            },

            randomChar(length) {
                let result = '';
                let charactersLength = this.rules.length;
                for ( let i = 0; i < length; i++ ) {
                    result += this.rules.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            },
            save(event) {
                console.log(event.target)
            }
        },
    }
</script>
