<template>
    <v-col cols="5">
        <v-dialog
                ref="dialog"
                v-model="dateModal"
                :return-value.sync="date"
                persistent
                width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                        v-model="dtText"
                        :label="label"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="dateModal = false">Cancel</v-btn>
                <v-btn color="primary" @click="date && (timeModal = true)">OK</v-btn>
            </v-date-picker>
        </v-dialog>

        <v-dialog
                ref="dialog2"
                v-model="timeModal"
                :return-value.sync="time"
                persistent
                width="290px"
        >
            <v-time-picker
                    v-if="timeModal"
                    v-model="time"
                    full-width
            >
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="timeModal = false">Cancel</v-btn>
                <v-btn color="primary" @click="set()">OK</v-btn>
            </v-time-picker>
        </v-dialog>
    </v-col>
</template>

<script>

    export default {
        name: "DateTimePicker",
        props: ['label', 'datetime'],
        data: () => ({
            date: "",
            dateModal: false,
            time: "",
            timeModal: false,
            dtText: '',
        }),
        watch: {
            datetime(val) {
                if (val) {
                    const arr = val.split(' ')
                    this.date = arr[0]
                    this.time = arr[1].substr(0, 5)

                    this.dtText = `${this.date} ${this.time}`
                }
            }
        },
        mounted() {
            this.date = moment().format('YYYY-MM-DD')
            this.time = moment().format('HH:mm')
        },
        methods: {
            set() {
                this.dtText = this.date +" "+ this.time;
                this.$refs.dialog.save(this.date);
                this.$refs.dialog2.save(this.time);
                this.$emit('update', this.dtText)
            }
        }
    }
</script>