<template>
    <v-card width="100%">
        <v-card-title>교육 과정</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <button class="btn btn-primary" @click="$router.push('/training/form')">
                <v-icon left>mdi-plus</v-icon>새 교육 생성
            </button>
        </v-card-actions>
        <v-card-text>
            <v-data-table
                    :headers="headers"
                    :items="trainings"
                    :items-per-page="itemsPerPage"
                    :page.sync="page"
                    :loading="isLoading"
                    loading-text="데이터 조회 중.. 잠시만 기다리세요."
                    hide-default-footer
                    @page-count="pageCount = $event"
                >
                <template v-slot:item.name="{ item }">
                    <router-link :to="{ path: `/training/${item.id}/trainees`}">{{ item.name }}</router-link>
                </template>
                <template v-slot:item.period_minutes="{ item }">
                    {{ item.period_minutes | comma }}분
                </template>
                <template v-slot:item.trainees_count="{ item }">
                    {{ item.trainees_count | comma }}명
                </template>
                <template v-slot:item.actions="{ item, index }">
                    <div class="text-right">
                        <v-btn small color="secondary"
                               @click="editItem(item, index)"
                               class="mr-1"
                        >편집</v-btn>
                        <v-btn small color="error"
                                @click="deleteItem(item, index)"
                        >삭제</v-btn>
                    </div>
                </template>
            </v-data-table>
            <v-divider></v-divider>
            <div class="text-center pt-2 mt-5">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    ></v-pagination>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
	    data() {
		    return {
		    	page: 1,
                pageCount: 0,
			    itemsPerPage: 10,
			    headers: [
                    {text: '#', sortable: true, value: 'id'},
				    {text: '교육 과정명', sortable: true, value: 'name'},
				    {text: '시작일', sortable: true, value: 'start_date'},
				    {text: '종료일', sortable: true, value: 'end_date'},
				    {text: '플레이 시간', sortable: true, value: 'period_minutes'},
				    {text: '교육생 수', sortable: true, value: 'trainees_count'},
				    {text: '', sortable: false, value: 'actions'},
			    ],
                trainings: [],
                isLoading: true,

		    }
	    },

        filters: {
	    	comma(val) {
			    return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		    }
        },
        mounted() {
	    	this.getList()
        },
        methods: {
	    	getList() {
	    		this.isLoading = true
			    this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training`).then(response => {
				    if (response.data.code === '0000') {
					    this.trainings = response.data.result
					    this.isLoading = false
				    }
			    })
		    },
	        editItem(item, index) {
	    		this.$router.push(`/training/${item.id}/form`)
            },
	    	deleteItem(item, index) {
	    		this.$store.commit('showDialog', {
	    			type: 'confirm',
                    message: `[${item.name}] 교육을 삭제하시겠습니까?`,
                    okCb: _ =>{
	    				if (item.trainees_count > 0) {
						    this.$store.commit('hideDialog')

                            setTimeout(_ => {
	                            this.$store.commit('showDialog', {
		                            type: 'confirm',
		                            message: `[${item.name}]에 등록된 교육생이 있습니다.\n등록된 교육생과 결과가 모두 삭제됩니다.\n\n그래도 삭제하시겠습니까?`,
		                            okCb: _ => {
			                            this.axios.delete(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training/${item.id}`).then(response => {
				                            if (response.data.code === '0000') {
					                            this.getList()
				                            }
			                            })
		                            }

	                            })
                            }, 50)
					    } else {
						    this.axios.delete(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training/${item.id}`).then(response => {
							    if (response.data.code === '0000') {
								    this.getList()
							    }
						    })
					    }
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
